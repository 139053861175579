<template>
  <v-layout class="fill-height">

    <!-- Navigation -->
    <v-navigation-drawer
      v-model="drawer"
      app
      floating
      class="elevation-1"
      :right="$vuetify.rtl"
      width="260"
    >
      <!-- Navigation menu info -->
      <template v-slot:prepend>
        <div class="d-flex pa-2 align-center">
          <img src="/images/v-logo-small.png" height="48" alt="logo" class="mr-1">
          <div>
            <div class="title font-weight-bold text-uppercase primary--text">{{ config.product.name }}</div>
            <div class="overline grey--text">{{ config.product.version }}</div>
          </div>
        </div>
        <v-divider class="mb-1"></v-divider>
      </template>

      <!-- Navigation menu -->
      <main-menu :menu="menu" />

      <!-- Navigation menu footer -->
      <template v-slot:append>
        <div class="pa-2 text-center">
          <v-btn to="#" block color="secondary">
            Documentation
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <!-- Toolbar -->
    <v-app-bar app flat color="surface">
      <v-card class="flex-grow-1 d-flex mt-3" color="surface" flat>
        <div class="d-flex flex-grow-1 align-center">

          <!-- search input mobile -->
          <v-text-field
            v-if="showSearch && showSearchRoute"
            v-model="searchQuery"
            append-icon="mdi-close"
            placeholder="Search"
            prepend-inner-icon="mdi-magnify"
            hide-details
            outlined
            solo
            flat
            autofocus
            @keyup.enter="onSearch"
            @click:append="showSearch = false"
          ></v-text-field>

          <div v-else class="d-flex flex-grow-1 align-center">
            <v-app-bar-nav-icon @click="toggleDrawer"></v-app-bar-nav-icon>

            <v-spacer></v-spacer>

            <!-- search input desktop -->

            <v-text-field
              v-if="showSearchRoute"
              ref="search"
              v-model="searchQuery"
              class="hidden-xs-only mx-2"
              placeholder="Search"
              prepend-inner-icon="mdi-magnify"
              hide-details
              filled
              dense
              style="max-width: 600px"
              rounded
              @keyup.enter="onSearch"
            ></v-text-field>

            <v-spacer class="d-block d-sm-none"></v-spacer>

            <v-btn class="d-block d-sm-none" icon @click="showSearch = true">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>

            <div :class="[$vuetify.rtl ? 'ml-1' : 'mr-1']">
              <toolbar-notifications />
            </div>

            <toolbar-user />
          </div>
        </div>
      </v-card>
    </v-app-bar>

    <v-container :fluid="!isContentBoxed" class="pt-3">
      <v-main>
        <router-view />
      </v-main>
    </v-container>

    <v-footer app inset>
      <div class="overline">
        <span v-if="user">logged in as ={{ user.first_name }} {{ user.last_name }}, </span>
        Copyright © 2020 <a href="https://docadmin.swaminarayan.faith" target="_blank">DocAdmin</a>, All rights Reserved
      </div>
      <v-spacer></v-spacer>
      <div class="overline">
        Made with <v-icon small color="pink">mdi-heart</v-icon>
      </div>
    </v-footer>
  </v-layout>
</template>

<script>
import config from '@/configs'
import MainMenu from '@/components/navigation/MainMenu'
import ToolbarUser from '@/components/toolbar/ToolbarUser'
import ToolbarNotifications from '@/components/toolbar/ToolbarNotifications'

import { mapGetters, mapState, mapMutations } from 'vuex'

export default {
  components: {
    MainMenu,
    ToolbarUser,
    ToolbarNotifications
  },
  data() {
    return {
      config,
      showSearch: false,
      isContentBoxed: false,
      searchQuery: null
    }
  },
  computed: {
    ...mapGetters('account', ['loggedIn', 'loggedInUser']),
    ...mapState('account', ['user']),
    ...mapState('app', ['menu']),
    drawer: {
      get() {
        return this.$store.state['app'].drawer
      },
      set(val) {
        this.setDrawer(val)
      }
    },
    showSearchRoute() {

      // Check if given route is true, if it is then hide Nav. 
      if (this.$route.path === '/app/search') {
        return false
      }
      
      return true
    }
  },
  methods: {
    ...mapMutations('app', ['toggleDrawer', 'setDrawer']),
    onSearch() {
      if (!this.searchQuery) { return }
      this.$router.push({ name: 'search', query: { q: this.searchQuery } })
    }
  },
  head: {
    link: [
      // adds config/icons into the html head tag
      ...config.icons.map((href) => ({ rel: 'stylesheet', href }))
    ]
  }
}
</script>

<style lang="scss">
.app-bar-full {
  .v-toolbar__content,
  .v-toolbar__extension {
    padding: 0;
  }
}
</style>